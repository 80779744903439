import { graphql } from "gatsby"

// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import CommonCard from "../components/Cards/CommonCard"
import SEO, { getSeoProps } from "../components/Common/SEO"
// import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"

// NOT IN USE

/**
 * @name Product
 * @description
 * @param param0
 */

const Produkt = ({ location, data }) => {
  const mainPath = getMainPath("productgroup")

  const {
    allContentfulProductTeaser: { nodes: content },
  } = data

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "product")

  // Parse the content, and get the needed banner props
  // const heroBannerProps = getBannerProps(content)

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {content.map((productItem) => {
              const props = {
                data: productItem,
                url: "nb" + mainPath + "/" + productItem.productGroup.slug,
              }
              return <CommonCard key={productItem.id} {...props} />
            })}
          </div>
        </div>
      </main>
    </>
  )
}

export const productQuery = graphql`
  {
    allContentfulProductTeaser(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        title
        slug
        productGroup {
          slug
        }
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
        }
        node_locale
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
  }
`

export default Produkt
